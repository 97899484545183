class TaskQueueManager {
  limit = 6;
  queue = new Set;
  pool = [];
  promise = null;
  resolver = () => {
  };
  computed = new Map;
  constructor(limit = 6) {
    this.limit = limit;
    this.queue = new Set;
    this.pool = [];
    this.promise = new Promise((resolve) => this.resolver = resolve);
    this.computed = new Map;
  }
  add(fns) {
    let _fns = Array.isArray(fns) ? fns : [fns];
    this.pool.push(..._fns);
    _fns.forEach((f) => {
      if (this.computed.has(f.id)) {
        console.warn(f.id, "is already in queue, it will be covered");
      }
      let resolver = (value) => {
      };
      let promise = new Promise((res, rej) => {
        resolver = res;
      });
      this.computed.set(f.id, { promise, resolver });
    });
    if (this.queue.size >= this.limit) {
      return this;
    }
    this.fillQueue();
    return this;
  }
  fillQueue() {
    while (this.limit - this.queue.size > 0 && this.pool.length > 0) {
      let task = this.pool.shift();
      this.execTask(task);
    }
  }
  async exec(task) {
    let res = null;
    try {
      res = await task.handler();
    } catch (err) {
      res = err;
    }
    this.computed.get(task.id)?.resolver(res);
    this.queue.delete(task);
    return Promise.resolve(true);
  }
  async execTask(task) {
    this.queue.add(task);
    await this.exec(task);
    while (this.queue.size < this.limit && this.pool.length > 0) {
      let _task = this.pool.shift();
      this.queue.add(_task);
      await this.exec(_task);
      if (this.pool.length + this.queue.size == 0)
        return this.resolver("end");
    }
  }
  get(queueId) {
    return this.computed.get(queueId)?.promise;
  }
}
export {
  TaskQueueManager
};

