// import cm from "@/util/webInit/initProxyService/_cache.js"

/**
 * 获取资源中的文件列表 - 同步调用
 * @param {{zyId: string, tokenId: string}} params
 * @returns 
 */
export function GetFileListByZyId_sync (params){
   const url = `/zy/Project/GetFileListByZyId?` + new URLSearchParams(params).toString();
   var request = new XMLHttpRequest;
   request.open("GET", url, false);
   request.send(null);
   var res;
   if (request.status == 200) {
    res = JSON.parse(request.responseText);
   } else  {
    console.error("Error " + request.status);
   }
   return res
}

/**
 * 获取资源中的文件列表 - 异步调用
 * @param {{zyId: string, tokenId: string}} params 
 * @returns 
 */
export async function GetFileListByZyId (params){
   const url = `/zy/Project/GetFileListByZyId?` + new URLSearchParams(params).toString();
   return fetch(url).then(res=>res.json())
}



/**
 * 获取资源中的文件地址 - 异步调用
 * @param {{zyid: string, tokenId: string, filetype?: string}} params
 * @returns {Promise<any>}
 */
export async function GetZyFileUrl(params){
   const paramStr = new URLSearchParams(params).toString()
   let url = '/zy/Project/GetZyFileUrl' + '?' + paramStr
   return fetch(url).then(res => {
      let result = res.json()
      return result
   });

}

/**
 * 获取资源中的文件地址 - 同步调用
 * @param {{zyid: string, tokenId: string, filetype?: string}} params
 * @return 
 */
export function GetZyFileUrl_sync(params){
   const paramStr = new URLSearchParams(params).toString()
   let url = '/zy/Project/GetZyFileUrl' + '?' + paramStr
   var request = new XMLHttpRequest;
   request.open("GET", url, false);
   request.send();
   var res;
   if (request.status == 200) {
    res = JSON.parse(request.responseText);
   } else  {
    console.error("Error " + request.status);
   }
   return res
}

/**
 * 
 */