<template>
  <div class="loading-mask">
    <img height="100%" width="100%" style="filter: brightness(.5);" object-fit="fill" 
      src="https://cdn.jiajutech.com/extension/login_bg.png">
      <div class="login-contain">
        <iframe src="https://mlogin.jiajutech.com/#/renderLogin?lan=zh-CN" width="100%" height="100%" frameborder="0"></iframe>
      </div>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        percent: 0,
        timer: null,
        showLoading: false
      }
    },
    mounted(){
      this.init()
    },
    methods:{
      handleSkip(){
        this.emitEnd()
      },
      emitEnd(){
        this.$emit("end", true)
      },
      init(){
        window.addEventListener("message", async event => {
          if(event.data.user){
            sessionStorage.setItem("login", JSON.stringify(event.data.user))
            this.$router.push({
              path: "/"
            })
          }
        });
      }
    }
  }
</script>

<style>
  .loading-mask{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .login-contain{
    position: fixed;
    width: 500px;
    height: 560px;
    top: 25%;
    background-color: #fff;
    left: calc((100vw - 560px) / 2);
    border-radius: 16px;
  }
</style>