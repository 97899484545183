<!--
 * @Author: hujj 1712891142@qq.com
 * @Date: 2022-12-15 16:08:58
 * @LastEditors: hujj 1712891142@qq.com
 * @LastEditTime: 2022-12-15 16:14:56
 * @FilePath: /software/src/components/HelloWorld.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div></div>
</template>

<script>
export default {

}
</script>

<style>

</style>