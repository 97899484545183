import {GetZyFileUrlByFileName} from "./getFileUrl.js"
import taskQueue from "./_taskQueue.js";
import { uuid } from "@/util/uuid.js";
function fetchImg(src){
  let resolver = ()=>{}
  let promise = new Promise((resolve, reject)=>resolver = resolve)
  const image = new Image;
  image.crossOrigin = "";
  image.src = src;
  image.onload = function(){
    resolver(image)
  }
  return promise
}

function getImageData(image) {
  var imageData = null;
  var canvas2 = document.createElement("canvas");
  var ctx = canvas2.getContext("2d");
  image.width = 256;
  image.height = 256;
  canvas2.width = image.width;
  canvas2.height = image.height;
  ctx.drawImage(image, 0, 0, 256, 256);
  imageData = ctx.getImageData(0, 0, canvas2.width, canvas2.height);
  return imageData.data;
}

export async function loadImg(zyId, tokenId, fileext, filename, i_pTask, i_eType, i_pMtl) {
  var zyId1 = UTF32ToString(zyId);
  var tokenId1 = UTF32ToString(tokenId);
  var fileext1 = UTF32ToString(fileext);
  var filename1 = UTF32ToString(filename);
  var realfilename = filename1 + "." + fileext1;
  var realfilename1 = filename1 + "." + "jpg";

  var url = await GetZyFileUrlByFileName(zyId1, tokenId1, realfilename1);
  if (url == "") {
    return;
  }
  let _taskId = uuid() 
  taskQueue.add({
    id: _taskId,
    handler: fetchImg.bind(null, url)
  })
  let oImage = await taskQueue.get(_taskId)
  let imageData = getImageData(oImage)
  const dataLength = imageData.length;
  var BPE = imageData.BYTES_PER_ELEMENT;
  var pData = _malloc(dataLength * BPE);
  HEAP8.set(imageData, pData / BPE >>> 0);
_JSLoadMaterialTexture(pData, oImage.width, oImage.height, i_pTask, i_eType, i_pMtl);
}