<!--
 * @Author: hujj 1712891142@qq.com
 * @Date: 2022-12-15 16:13:38
 * @LastEditors: hujj 1712891142@qq.com
 * @LastEditTime: 2022-12-15 16:19:14
 * @FilePath: /software/src/components/Top.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="top">
    <div>嘉居设计平台简化版</div>
  </div>
</template>

<script>
export default {
  name: "TopBanner",
};
</script>

<style lang="less" scoped>
.top {
  background: rgba(248, 249, 251, 0.8);
  width: 100%;
  height: 100%;
  min-height: 50px;
  display: flex;
  justify-content: flex-start;
  padding: 0 12px;
  position: relative;
  align-items: center;
  flex-direction: row;
  align-content: center;
  flex-wrap: nowrap;
}
</style>