<!--
 * @Author: hujj 1712891142@qq.com
 * @Date: 2022-12-15 16:08:58
 * @LastEditors: hujj 1712891142@qq.com
 * @LastEditTime: 2022-12-15 16:18:44
 * @FilePath: /software/src/App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
export default {
  name: "App",
  components: {
  },
	beforeCreate()
	{
		console.log("beforeCreate -->创建前");
		console.log(this.message);
	},
	created(){
		console.log("created -->创建后");
		console.log(this.message);
	},
	beforeMount(){
		console.log("beforeMount --> 渲染前");
		console.log(this.message);
	},
	mounted(){
		console.log("mounted --> 渲染后");
	},
	beforeUpdate(){
		console.log("beforeUpdate --> 修改前");
		console.log(this.message);
	},
	updated(){
		console.log("updated --> 修改后");
		console.log(this.message);
	},
	beforeDestroy(){
		console.log("beforeDestroy --> 销毁前");
		console.log(this.message);
	},
	destroyed(){
		console.log("destroyed --> 销毁后");
		console.log(this.message);
	}
};
</script>

<style lang="less">
body,
html {
  padding: 0;
  margin: 0;
  overflow: hidden;
}
#app {
  overflow: hidden;
	height: 100vh;
}
</style>
