import service_v2 from "@/api/proxy/service.js";


/**
 * file bind zy
 * @param {any} res 
 * @param {string} zyId1 
 */
function bindZy(res, zyId1) {
  if (res.Code == 0) {
    res.SuccessReturn.forEach(item => {
      var PathLen = lengthBytesUTF32(item.FileUrl) + 12;
      var ptr1 = _malloc(PathLen);
      stringToUTF32(item.FileUrl, ptr1, PathLen);
      var PathLen2 = lengthBytesUTF32(zyId1) + 12;
      var ptr2 = _malloc(PathLen2);
      stringToUTF32(zyId1, ptr2, PathLen2);
      var PathLen3 = lengthBytesUTF32(item.FileName) + 12;
      var ptr3 = _malloc(PathLen3);
      stringToUTF32(item.FileName, ptr3, PathLen3);
      var filetype = "";
      if (item.FileType != null) {
        filetype = item.FileType;
      }
      var PathLen4 = lengthBytesUTF32(filetype) + 12;
      var ptr4 = _malloc(PathLen4);
      stringToUTF32(filetype, ptr4, PathLen4);
      Module["StreamDataList"]["PushUrlData"](ptr2, PathLen2, ptr1, PathLen, ptr3, PathLen3, ptr4, PathLen4);
    });
  }
}

function proxyWrap(zyId, tokenId) {
  let res = service_v2.GetFileListByZyId({zyId, tokenId})
  //bindZy(res, arguments[0])
  return res
}


let normalizeWrap = function (fn) {
  return function(){
    let arg = [UTF32ToString(arguments[0]), UTF32ToString(arguments[1])]
    return fn.call(window, ...arg)
  }
}

export const GetFileListByZyId = normalizeWrap(proxyWrap)