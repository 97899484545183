import { uuid } from "@/util/uuid"
import cm from "@/util/webInit/initProxyService/_cache"
import taskQueue from "@/util/webInit/initProxyService/_taskQueue"
// import proxyIndex from "./proxyIndex.js"

/**
 * 
 * @param {Function} fn 
 * @param {{
 *    cache: boolean,
 *    cacheKey: Function
 *    transform: Function,
 *    sync?: boolean
 * }} config 
 * @returns {()=>{Promise<any>} | ()=>any}
 */
function cacheWrap(fn, config = {
	cache: false,
	cacheKey: () => { },
	transform: (v) => v,
	sync: true
}) {
	if (typeof fn !== "function") return fn
	if (!config.cache) return fn
	if (!config.cacheKey || (typeof config.cacheKey !== 'function')) {
		console.warn('cacheKey is not a function or not defined')
		return fn
	}

	
	/** sync */
	if (config.sync) {
		return function () {
			const CACHE_KEY = config.cacheKey(...arguments)
			if (cm.has(CACHE_KEY) && cm.get(CACHE_KEY).hasOwnProperty('value')) {
				return cm.get(CACHE_KEY).value
			}
			let value = fn(...arguments)
			let cacheRes = config.transform(value)
			if(cacheRes !== undefined && cacheRes !== null) {
				cm.add(CACHE_KEY, cacheRes)
			}
			return value
		}
	}
	/** async */
	return async function () {
		const CACHE_KEY = config.cacheKey(...arguments)
		if (cm.has(CACHE_KEY)) {
			let _t = cm.get(CACHE_KEY)
			if (_t.hasOwnProperty('value')) return Promise.resolve(_t.value)
			return _t.promise
		}
		let cache_value = fn(...arguments).then((res) => {
			let cacheRes = config.transform(res)
			if(cacheRes == undefined || cacheRes == null) {
				cm.del(CACHE_KEY)
			}else{
				cm.get(CACHE_KEY).value = cacheRes
			}
			return cacheRes
		})
		cm.add(CACHE_KEY, cache_value)
		return cache_value
	}
	
}
/**
 * only limit async function
 * @return {() => Promise<any>} 
 */
function queueWrap(fn, config={limit: false}) {
	if (!config.limit) return fn
	return async function(){
		let _taskId = uuid()
		let args = [...arguments]
		taskQueue.add({
			id: _taskId,
			handler: fn.bind(null, ...args)
		})
		return taskQueue.get(_taskId)
	}
}

/**
 * 
 * @param {{
 * 	handler: Function,
 * 	option: {
 * 		cache: boolean,
 *   	cacheKey: Function
 *   	transform: Function,
 * 		limit: false,
 *   	sync?: boolean
 * }
 * }} param 
 * @returns 
 */
function injectMiddleware(param = {handler: function(){}, option: {cache: false, limit: false}}){
	let {handler, option} = param
	if (option.limit) handler = queueWrap(handler, option)
	if (option.cache) handler = cacheWrap(handler, option)
	return handler
}

/**
 * services
 * @param {{name: string, handler: Function, option: {
 * 		cache: boolean,
*   	cacheKey: Function
*   	transform: Function,
* 		limit: false,
*   	sync?: boolean
* }}[]} services
 * @returns 
 */
function createService(services) {
	let o = {}
	services.forEach((srv)=>{
		o[srv.name] = injectMiddleware({
			handler: srv.handler,
			option: srv.option
		})
	})
	return o
}

export default createService