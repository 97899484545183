import * as service from "../zyFile/zyFile.js"

export default [
	{
		name: "GetFileListByZyId",
		handler: service.GetFileListByZyId,
		option: {
			cache: true,
			limit: true,
			sync: false,
			transform: (res)=>{
				if(res.Code == 0){
					return res
				}
			},
			cacheKey: (params)=>{
				return `GetFileListById//tokenId=${params.tokenId}/zyId=${params.zyId}`
			}
		}
	},
	{
		name: "GetZyFileUrl",
		handler: service.GetZyFileUrl,
		option: {
			cache: true,
			limit: true,
			sync: false,
			transform: (res)=>{
				if(res.Code == 0){
					return res
				}
			},
			cacheKey: (params)=>{
				return `GetZyFileUrl//tokenId=${params.tokenId}/zyid=${params.zyid}/filetype=${params.filetype}`
			}
		}
	},
  {
    name: "GetFileListByZyIdSync",
    handler: service.GetFileListByZyId_sync,
    option: {
      cache: true,
      sync: true,
      transform: (res)=>{
        if(res.Code == 0){
					return res
				}
      },
      cacheKey: function(params){
        return `GetFileListById//tokenId=${params.tokenId}/zyId=${params.zyId}`
      }
    }
  }
]