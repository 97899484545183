<template>
  <div class="loading-mask">
    <img height="100%" width="100%" style="filter: brightness(.55);" object-fit="fill" 
      src="https://cdn.jiajutech.com/extension/login_bg.png">
    <div v-show="showLoading" style="width:50vw;position:absolute; left: 25vw;bottom: 100px;z-index: 100;display: flex; align-items: center;">
      <Progress :percent="percent" status="active" text-inside :stroke-width="18" stroke-color="#136dcd"/>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        percent: 0,
        timer: null,
        showLoading: false
      }
    },
    mounted(){
      this.init()
    },
    methods:{
      handleSkip(){
        this.emitEnd()
      },
      emitEnd(){
        this.percent = 100
        // 处理本地情况，避免瞬间消失
        setTimeout(() => {
          this.$emit("end", true)
        }, 250);
      },
      startLoading(){
        this.showLoading = true
        window.mitt.on('wasm-initialized', (res)=> {
          // 处理本地情况，避免瞬间消失
          setTimeout(() => {
            clearInterval(this.timer)
            this.emitEnd()
          }, 1200);
        })
        this.timer = setInterval(() => {
          if(this.percent == 99) {
            return clearInterval(this.timer)
          }
          this.percent += 1
        }, 200);
      },
      init(){
        try{
          const user = sessionStorage.getItem("login")
          if(user){
            this.startLoading()
          }
        }catch{

        }
        window.addEventListener("message", async event => {
          if(event.data.user){
            localStorage.setItem("login", JSON.stringify(event.data.user))
            this.startLoading()
          }
        });
      }
    }
  }
</script>

<style>
  .loading-mask{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .login-contain{
    position: fixed;
    width: 500px;
    height: 560px;
    top: 25%;
    background-color: #fff;
    left: calc((100vw - 560px) / 2);
    border-radius: 20px;
  }
</style>