import VueRouter from "vue-router";

import Index from "@/page/index/index.vue"
import Login from "@/page/login/index.vue"

const router = new VueRouter({
  mode: "history",
  routes : [
    {
      path: "/",
      component: Index
    },
    {
      path: "/login",
      component: Login
    }
  ]
})

router.beforeEach((to, from, next)=>{
  if(to.path == "/login"){
    return next()
  }
  let user = sessionStorage.getItem("login")
  if(!user){
    next('/login')
  }
  next()
})

export default router