let _log = console.log
console.log = ()=>{}
export default {
  color: function(){
    let colors = ``
    Array.from(arguments).forEach(c => {
      colors += ' %c ' + c
    });
    _log(colors, 'background-image: linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%);color:transparent;-webkit-background-clip: text;');
  },
  green: function(){
    let colors = ``
    Array.from(arguments).forEach(c => {
      colors += ' %c ' + c
    });
    _log(colors, 'color: #96e6a1');
  }
}
