<template>
  <div>
    <div class="left">
      <div class="menu">
        <div class="area">
          <div class="area-content">
            <div class="area-item">
              <div class="item-icon">
                <Icon type="ios-albums-outline" />
              </div>
            </div>
            <div class="area-item">
              <div class="item-icon">
                <Icon type="ios-apps" />
              </div>
            </div>
            <div class="area-item">
              <div class="item-icon">
                <Icon type="ios-barcode" />
              </div>
            </div>
            <div class="area-item">
              <div class="item-icon">
                <Icon type="logo-buffer" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="slide">
      <div class="slide-head">
        <div class="title">公共库</div>
      </div>
      <div class="slide-content">
        <div class="img-wrap">
          <template v-for="(item, index) in imglist">
            <img
              :src="item.src"
              alt=""
              :key="index"
              @click="isDragImg=false"
              @mousedown="handleMouseDown(item)"
              @mouseleave="handleMouseLeave"
              />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Archive} from 'libarchive.js/main.js';

//解压缩
function getFileFromUrl(url, fileName)
{
  return new Promise((resolve, reject) => {
    var blob = null;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.setRequestHeader('Accept', '');
    xhr.responseType = "blob";
    // 加载时处理
    xhr.onload = () => {
    // 获取返回结果
      blob = xhr.response;
      let file= new File([blob], fileName, { type: '' });
      // 返回结果
      resolve(file);
    };
    xhr.onerror = (e) => {
      reject(e)
    };
    // 发送
    xhr.send();
  });
}

function readDecArrayBuffer(file)
{
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = function() {           
      resolve(reader.result);
    };

  });
}


 function readImageBuffer(blobURL)
{
  function getImageData(image) {
        var imageData = null
        var canvas2 = document.createElement('canvas')
        var ctx = canvas2.getContext('2d'); 
        image.width = 512
        image.height = 512
        canvas2.width = image.width
        canvas2.height = image.height
        //console.log('JSLoadImg-6');
        ctx.drawImage(image, 0, 0); 
        imageData = ctx.getImageData(0, 0, canvas2.width, canvas2.height);
        //console.log('JSLoadImg-7');
        return imageData.data
   }

  return new Promise( (resolve, reject) => {
      var imageData = null
      const image = new Image()
      image.crossOrigin = ''
      //console.log("图片的url:",blobURL);
      image.src = blobURL; 
      //console.log('JSLoadImg-4');
      image.onload = function () {

        //console.log('JSLoadImg-5');
        imageData = getImageData(image);
        resolve(imageData);
      }

  });
}





async	function extractFiles(url, decResID)
{
  var matPath = '/DecData/' + decResID + '.mat';
  var meshPath = '/DecData/' + decResID + '.mesh';

  const file = await getFileFromUrl(url,"temp.7z");  //解压缩
  const archive = await Archive.open(file);	
  const filesObject = await archive.getFilesObject();
  //console.log(filesObject);
  const fileArray = await archive.getFilesArray();
 // console.log(fileArray);
  //let obj = await archive.extractFiles();
  //console.log(obj);

  for(let i = 0; i < fileArray.length; i++)
  {
    const fileObj = fileArray[i];
    const fileExtract = await fileObj['file'].extract();
    const result = await readDecArrayBuffer(fileExtract)

    var filename = fileExtract.name;
    var lastIndex = filename.lastIndexOf("."); 
    var ext = filename.substr(lastIndex+1);
    //console.log(filename);
    if(ext == "mat")
    {
     // console.log("读取解压后的材质文件mat");
      var bufMesh = new Uint8Array(result);
     // console.log(bufMesh);
      var streamMesh = await FS.open(matPath, 'w+');				
      FS.write(streamMesh, bufMesh, 0, bufMesh.length, 0);
      FS.close(streamMesh);					
    }
    else if(ext == "mesh" || ext == "MESH")
    {			
      console.log("读取解压后的数据文件mesh");
      var bufMesh = new Uint8Array(result);
      var streamMesh = await FS.open(meshPath, 'w+');				
      FS.write(streamMesh, bufMesh, 0, bufMesh.length, 0);
      FS.close(streamMesh);
    }
    else if(ext == "jpg")
    {	
        console.log("读取解压后的贴图文件jpg");
        let blob = new Blob([result], {
          type: "application/octet-stream",
        });
        // 将blob对象转为一个URL
        var blobURL = window.URL.createObjectURL(blob);

        var imagePath = '/DecData/' + decResID + '_' + filename;
       // console.log(imagePath);
        const bufImg = await readImageBuffer(blobURL);
        var streamImage = await FS.open(imagePath, 'w+');	
       // FS.write(streamImage, 512, 0, bufImg.length, 0);			
        FS.write(streamImage, bufImg, 0, bufImg.length, 0);
        FS.close(streamImage);
       // console.log(bufImg.length);
       // console.log(bufImg);
    }
  }

  return meshPath;
}
	
  export default {
    mounted(){
		  console.log("mounted --> 渲染后");
			this.$nextTick(()=>{
        this.handleZip()
		  });
	  },
  data() {
    return {
      isDragImg: false,
      current: {},
      imglist: [
          {
            //组合柜
            name: "",
            id:  "6abf7eee7bd7071aca2466c6b16723bd",
            url: "https://obs-uat.obs.cn-gdgz1.ctyun.cn/temp/20240411/webtest-zuhegui.hdf",
            src: "https://cdn.jiajutech.com/jiaju/jiajucloud/basic/2022/6/10/e7422f80-eef9-45bd-830b-36e092008cf5.jpg",
          },
          {
           //空间
            name: "",
            id:  "75364970d39528a6c22533a4b2a7411f",
            url: "https://obs-uat.obs.cn-gdgz1.ctyun.cn/temp/20240311/webtest-room.hdf",
            src: "https://cdn.jiajutech.com/jiaju/2022/9/21/9c8d17d1-545c-4abb-840b-f57a1efb4836.jpg",
          },
          {
            //门板
            name: "",
            id: "b793c862b2c7709f54c9e6255febc2d8",
            url: "https://obs-uat.obs.cn-gdgz1.ctyun.cn/temp/20240411/webtest-menmban.hdf",
            src: "https://cdn.jiajutech.com/jiaju/2022/9/21/f4ad1fb4-96e2-4ad4-9ddc-bd72e7740fd9.jpg",
          },

          {
            //场景
            name: "",
            id:  "6abf7eee7bd7071aca2466c6b1672311",
            url: "https://obs-uat.obs.cn-gdgz1.ctyun.cn/temp/20240422/webceshi.hdf",
            src: "https://cdn.jiajutech.com/jiaju/jiajucloud/basic/2022/6/10/e7422f80-eef9-45bd-830b-36e092008cf5.jpg",
          },
      ],
    };
  },
  methods: {
    async handleZip() {
      Archive.init({
        workerUrl: '/libarchive.js/dist/worker-bundle.js'
      });
      console.log("Archive.init");
	  },

    handleMouseDown(item) {
        //console.log("鼠标按下开始");
        //console.log("当前拖拽对象是：", item);
        this.isDragImg = true
        this.current = item;
    },

    async  handleMouseLeave(event) {
        if(!this.isDragImg) return
        this.isDragImg = false
        //console.log(this.current);
        //console.log("鼠标按下结束");

        if (typeof(this.current)  === 'undefined') {
          //console.log("选择的饰品为undefined");
          return;
        }
        else
        {
          //console.log("选择的饰品不为undefined");
        }

        var url = this.current.url;
        //console.log(url);
        var decResID = this.current.id;
        //console.log(decResID);
        this.current = undefined; //清除掉
        let canvas = document.getElementById("canvas");
        //console.log(canvas);
        var rect = canvas.getBoundingClientRect();
        //console.log(rect);
        var x = event.clientX;
        var y = event.clientY;
        x -= rect.left;
        y -= rect.top;
        //console.log("开始读取饰品");

        //加载饰品，暂时注释
        //const meshPath = await extractFiles(url, decResID);	
        //console.log(meshPath);
	      //const ptr2 = allocateUTF8(meshPath);
        //console.log(ptr2, x, y);
        const ptr = allocateUTF8(url);
        Module.HandleDropDec(x, y, ptr);
    }
  },
  };
</script>

<style lang="less" scoped>
.left {
  position: absolute;
  pointer-events: none;
  left: 0px;
  top: 50px;
  bottom: 0px;
  transition: left 300ms ease 0s;
  .menu {
    width: 54px;
    background: rgba(248, 249, 251, 0.8);
    float: left;
    box-sizing: border-box;
    height: 100%;
    padding: 0 0 12px;
    pointer-events: auto;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    z-index: 1;
  }
  .area {
    height: 100%;
    position: relative;
    overflow-y: auto;
    width: fit-content;
    border-radius: 0 4px 4px 0;
    // overflow: hidden;
    &-content {
      min-height: 100%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: center;
      align-items: center;
    }
    &-item {
      display: inline-flex;
      z-index: 2;
      flex-direction: row;
      clear: both;
      white-space: nowrap;
      width: 100%;
      padding: 3px 0;
    }
    .item-icon {
      text-align: center;
      height: 44px;
      width: 54px;
      padding: 4px;
      z-index: 1;
      flex-shrink: 0;
    }
  }
}

.slide {
  z-index: 999;
  position: absolute;
  left: 55px;
  top: 50px;
  width: 250px;
  float: left;
  box-sizing: border-box;
  height: calc(100% - 50px);
  overflow: hidden;
  box-shadow: 0px 2px 8px rgba(3, 9, 17, 0.08);
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  &-head {
    position: relative;
    width: 250px;
    box-sizing: border-box;
    padding: 16px;
  }
  &-content {
    padding-top: 12px;
    height: 100%;
    border-top: 1px solid rgba(49, 55, 62, 0.1);
    margin-top: 0px;
    margin-bottom: 0px;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
    .img-wrap {
      img {
        padding: 2px;
        max-width: 50%;
        min-width: 50%;
        flex: 0 0 50%;
        object-fit: cover;
        cursor: pointer;
        border-radius: 12px;
      }
    }
  }
}
</style>