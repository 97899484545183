import { fetchWasm } from "./initWasm/index.js"
import {
  GetFileListByZyId,
  GetFileFromUrl,

  GetZyFileUrlByFileType,
  GetZyFileUrlByFileTypeSync,
  GetZyFileUrlByFileName,
  loadImg

} from "./initProxyService/index.js"

export default function () {
  fetchWasm().then(()=>{
    wasmImports.GetFileListByZyId = GetFileListByZyId;

    wasmImports.JSGetZyFileUrlByFileType = GetZyFileUrlByFileType
    window._JSGetZyFileUrlByFileType = GetZyFileUrlByFileType
    
    wasmImports.JSGetZyFileUrlByFileTypeSync = GetZyFileUrlByFileTypeSync
    window._JSGetZyFileUrlByFileTypeSync = GetZyFileUrlByFileTypeSync
    
    wasmImports.JSGetZyFileUrlByFileName = GetZyFileUrlByFileName
    window._JSGetZyFileUrlByFileName = GetZyFileUrlByFileName

    wasmImports.getFileFromUrl = GetFileFromUrl
    window._getFileFromUrl = GetFileFromUrl

    window._JSLoadImg = loadImg
    wasmImports.JSLoadImg = loadImg
  }) 
}