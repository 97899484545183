import Vue from 'vue'
import App from './App.vue'
import router from "./router/index.js"
import VueRouter from 'vue-router'
import ViewUI from 'view-design';
import mitt from "mitt"

import 'view-design/dist/styles/iview.css';
window.mitt = mitt()
Vue.use(ViewUI);
Vue.config.productionTip = false
Vue.use(VueRouter)
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
