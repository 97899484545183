<!--
 * @Author: hujj 1712891142@qq.com
 * @Date: 2022-12-29 09:41:30
 * @LastEditors: hujj 1712891142@qq.com
 * @LastEditTime: 2022-12-29 10:00:57
 * @FilePath: /jiaju-embed/src/components/RightCanvas/CanvasContent.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div id="container" class="container">
      <div class="actions">
        <Button type="primary" @click="OpenDialog(100, 200, 300)">长宽高</Button>
      </div>
      <div style="position: fixed; top: 10px;left: 160px;">
        <Button type="primary" @click="handleOpenFile" icon="ios-folder-outline" style="padding:3px 8px">打开</Button>
      </div>
      <Modal v-model="visible" title="设置" :closable="false" :mask-closable="false" @on-ok="ok" @on-cancel="cancel">
        <Form ref="formInline" :model="form" inline :label-width="40">
          <FormItem label="长:">
            <InputNumber v-model="form.long"></InputNumber>
          </FormItem>
          <FormItem label="宽:">
            <InputNumber v-model="form.width"></InputNumber>
          </FormItem>
          <FormItem label="高:">
            <InputNumber v-model="form.height"></InputNumber>
          </FormItem>
        </Form>
      </Modal>
      <canvas id="canvas" class="canvas"></canvas>
    </div>
  </div>
</template>

<script>
  export default {
    name: "CanvasContent",
    data() {
      return {
      visible: false,
        form: {
          long: null,
          width: null,
          height: null,
        },
      };
    },
  mounted() {
    window.mitt.on("wasm-load", (isLoad)=>{
      if(isLoad == true){
        this.initCanvas();
      }
    })
    const _this = this;
    window.addEventListener(
      "OpenDialog",
      function (event) {
        //console.log(event);
        _this.form.long = event.detail.x;
        _this.form.width = event.detail.y;
        _this.form.height = event.detail.z;
        _this.visible = true;
      },
      false
    );

    // canvas的鼠标事件
  let canvas = document.getElementById("canvas");
    canvas.addEventListener(
      "mousedown",
      function (event) {
        // 左键点击事件
        if (event.button == 0) {
          //console.log("======您点击了鼠标左键======");
			    Module.HandleLButtonDown(event.x, event.y)
        }
        // 中键点击事件
        if (event.button == 1) {
          //console.log("======您点击了鼠标中键======");
		  Module.HandleMButtonDown(event.x, event.y)
        }
        // 右键点击事件
        if (event.button == 2) {
          //console.log("======您点击了鼠标右键======");
			//Module.HandleRButtonDown(event.x, event.y)		
        }
      },
      false
   );

// 鼠标滚轮事件
    canvas.addEventListener(
      "mousewheel",
      function (event) {
        // 滚轮向下滚动
        if (event.wheelDelta < 0) {
          //console.log("======向下滚动了滚轮======");
        }
        // 滚轮向上滚动
          if (event.wheelDelta > 0) {
            //console.log("======向上滚动了滚轮======");
          }

  Module.HandleMouseWheel(event.wheelDelta)
  },
        false
  );
      
      // 鼠标左键双击事件
      canvas.ondblclick = function (event) {
        //console.log(event);
        //console.log("======左键双击了======");
      }

      // 鼠标弹起事件
      canvas.onmouseup = function (event) {
          //console.log("======鼠标弹起了======");
  if (event.button == 0) {
  //console.log("左键弹起")
  // Module._TestJSExport();
  Module.HandleLButtonUp(event.x, event.y);


 // Module._JSZip();
  }

  if (event.button == 1) {
  //console.log("中键弹起")
  Module.HandleMButtonUp(event.x, event.y)
  }

  if (event.button == 2) {
  //console.log("右键弹起")
  Module.HandleRButtonUp(event.x, event.y)

  }

      }
   // 鼠标移动事件
      canvas.addEventListener(
        "mousemove",
        function (event) {
         // console.log("当前x:" + event.x + "， 当前y：" + event.y);
          if (event.which == 1) {
            //console.log("当前移动中按下了左键");
			Module.HandleMouseButtonDownMove(event.x, event.y, event.which)
          }
          else if (event.which == 2) {
            //console.log("当前移动中按下了中键");
			Module.HandleMouseButtonDownMove(event.x, event.y, event.which)
          }
          else if (event.which == 3) {
            //console.log("当前移动中按下了右键");
			Module.HandleMouseButtonDownMove(event.x, event.y, event.which)
          }
		  else 
		  {
			Module.HandleMouseMove(event.x, event.y)
		  }
  

        },
        false
      );
  // 键盘监听事件
      document.onkeydown = function (event) {
        //console.log(event.key, event.keyCode);
        let key = window.event.key;
        if (key === 's' && event.ctrlKey) {
        window.event.preventDefault(); //关闭浏览器快捷键
        //console.log("保存");
      }

      if (key === 'Home') {
        window.event.preventDefault(); //zoomall
        let ptr = 'CX_DFT_ZoomAll';
        var len = lengthBytesUTF32(ptr) + 12;
        var ptr1 = stackAlloc(len);
        stringToUTF32(ptr, ptr1, len);
        Module.HandleCommand(ptr1);
      }
      this.keyCode = key;
      //console.log(this.keyCode);
    };

  },
  methods: {
  initCanvas() {
      const canvas = document.getElementById("canvas");
      const container = document.getElementById("container");
      canvas.width = container.clientWidth;
      canvas.height = container.clientHeight;
      //console.log("Module.canvas = canvas");
      window.Module.canvas = canvas;
      window.Module.onRuntimeInitialized = () => {
          // eslint-disable-next-line
          window.mitt.emit("wasm-initialized", true)
          ImageViewer.run(container, canvas);
      };
      window.onresize = () => {
          canvas.width = container.clientWidth;
          canvas.height = container.clientHeight;
      };
  },
  // open file
  handleOpenFile(){
    let input = document.createElement("input")
    input.type = 'file'
    // 打开文件管理器之前。wasm command
    input.click()
    input.addEventListener("change", (e)=>{
      let fileList = input.files
      //console.log(fileList);
      const file = fileList.item(0);
      if (file)
      {
          //console.log(file.name);
          var fileurl = URL.createObjectURL(file);
          //console.log(fileurl);
          const ptr = allocateUTF8(fileurl);
          var x = 0;
          var y = 0;
          Module.HandleDropDec(x, y, ptr);
          URL.revokeObjectURL(fileurl);
      } 
    })  
  },
  OpenDialog(x, y, z) {
      var ptr = "CX_DFT_ChangeDecSize";
      var len = lengthBytesUTF32(ptr) + 12;
      var ptr1 = stackAlloc(len);
      stringToUTF32(ptr, ptr1, len);
      Module.HandleCommand(ptr1);
  },
  ok() {
  //console.log("ok");


  
   // 调用方法
   var     x = this.form.long
   var     y = this.form.width
   var     z = this.form.height
  Module.HandleWDHDlg(x,y,z,1);
  },
  cancel() {
  console.log("cancel");
  // 调用方法
  var       x = this.form.long
   var     y = this.form.width
   var     z = this.form.height
  Module.HandleWDHDlg(x,y,z,1);
  },
  },
  };
</script>

<style lang="less" scoped>
.actions {
  position: fixed;
  right: 20px;
  top: 10px;
}
.canvas {
  height: calc(100vh - 50px);
  width: calc(100% - 55px);
  margin-left: 55px;
}
</style>
