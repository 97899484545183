import log from "../log/index.js"

class CacheManager {
  cache;
  constructor() {
    this.cache = new Map;
  }
  add(key, value){
    if (key === undefined || value === undefined || key === null || value === null) return
    const _value = {}
    if(value instanceof Promise){
      _value.promise = value
    }else{
      _value.value = value
    }
    this.cache.set(key, _value)
  }
  has(key){
    return this.cache.has(key)
  }
  get(key){
    // log.color('get_cache: ', key)
    return this.cache.get(key)
  }
  del(key){
    return this.cache.delete(key)
  }
  sync(fn) {
    let cache = this.cache;
    return function() {
      let key = Array.from(arguments).join("//");
      if (cache.has(key)) {
        // log.color('get-from-cache：' + key)
        return cache.get(key);
      }
      let result = fn(...arguments);
      // log.color('set-cache：'+ key)
      cache.set(key, result);
      return result;
    };
  }
  async(fn) {
    let cache = this.cache;
    return async function() {
      let key = Array.from(arguments).join("//");
      if (cache.has(key)) {
        // log.green('get-from-async-cache：' + key)
        return cache.get(key);
      }
      let result = await fn(...arguments);
      // log.green('set-async-cache：' + key)
      cache.set(key, result);
      return result;
    };
  }
}
export {
  CacheManager
};