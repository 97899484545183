<!--
 * @Author: hujj 1712891142@qq.com
 * @Date: 2022-12-15 16:08:58
 * @LastEditors: hujj 1712891142@qq.com
 * @LastEditTime: 2022-12-15 16:18:44
 * @FilePath: /software/src/App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
		<div :style="{opacity: loading ? 0 : 1}">
			<LeftMenu />
			<TopBanner />
			<MainContent />
			<CanvasContent />
		</div>
		<LoadingMask v-if="loading" @end="loading=false" style="position:fixed;z-index:1"/>
  </div>
</template>

<script>
import LeftMenu from "./components/LeftMenu.vue";
import TopBanner from "./components/TopBanner.vue";
import MainContent from "./components/MainContent.vue";
import CanvasContent from "./components/RightCanvas/CanvasContent.vue";
import LoadingMask from "./components/Loading/index.vue"
import { Archive } from 'libarchive.js/main.js';

import webInit from "@/util/webInit/index.js"
import log from "@/util/log/index.js"
export default {
  name: "App",
  components: {
    LeftMenu,
    TopBanner,
    MainContent,
    CanvasContent,
		LoadingMask
  },
	data(){
		return {
			loading: true
		}
	},
	async beforeCreate()
	{
		console.warn("beforeCreate -->创建前");
		log.color('reload sync fetch')
		webInit()

	},
	created(){
		console.log("created -->创建后");
		console.log(this.message);
			//	this.$nextTick(()=>{
			this.handleZip()
		//});
	},
	beforeMount(){
		console.log("beforeMount --> 渲染前");
		console.log(this.message);
	},
	mounted(){
		console.log("mounted --> 渲染后");
		// let mask = document.createElement("div")
		// mask.style = "position:fixed;height:100vh;width:100vw;top:0;left:0;background-color:rgba(0,0,0,.4);z-index:1"
		// document.querySelector("#app").style.display = "none"
		// document.body.appendChild(mask)
	},
	beforeUpdate(){
		console.log("beforeUpdate --> 修改前");
		console.log(this.message);
	},
	updated(){
		console.log("updated --> 修改后");
		console.log(this.message);
	},
	beforeDestroy(){
		console.log("beforeDestroy --> 销毁前");
		console.log(this.message);
	},
	destroyed(){
		console.log("destroyed --> 销毁后");
		console.log(this.message);
	}
,

  methods: {
    async handleZip() {
		Archive.init({
			workerUrl: '/libarchive.js/dist/worker-bundle.js'
		});
		 console.log("Archive.init");
	}
  }
};
</script>

<style lang="less">
body,
html {
  padding: 0;
  margin: 0;
  overflow: hidden;
}
#app {
  overflow: hidden;
	height: 100vh;
}
</style>
