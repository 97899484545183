import service_v2 from '@/api/proxy/service.js'

/**
 * 过滤文件类型
 * @param {any[]} res 
 * @param {string} fileType 
 * @returns {string}
 */
function filterType(res, zyId, fileType){
  if (res.Code !== 0) return ""
  let ret = ""
  res.SuccessReturn.forEach(item => {
    let _fileType = item.FileType ? item.FileType : "";
    if (_fileType.toLowerCase() == fileType.toLowerCase()) {
      ret = item.FileUrl;
      }
  });
  return ret;
}

/**
 * 过滤文件名
 * @param {any[]} res 
 * @param {string} fileName 
 * @returns {string}
 */
function filterName(res, fileName){
  if (res.Code !==0 ) return ""
  let ret = ""
  res.SuccessReturn.forEach(item => {
    let _fileType = item.FileType ? item.FileType : "";
    if ((_fileType == "") && (item.FileName.toLowerCase() == fileName.toLowerCase())) {
      ret = item.FileUrl;
    }
  });
  return ret;
}


/**
 * 获取文件url filter type - 异步调用
 * @param {string} zyId 
 * @param {string} tokenId 
 * @param {string} fileType 
 */
async function _getZyFileUrlByType(zyId, tokenId, fileType){
  const res = await service_v2.GetFileListByZyId({
    zyId: zyId, 
    tokenId: tokenId
  })
  let arrResult = filterType(res, zyId, fileType)
  return arrResult
}


let normalizeWrap = function (fn) {
  return function(){
    let arg = Array.from(arguments).map(item=>UTF32ToString(item))
    return fn.call(window, ...arg)
  }
}

/**
 * 获取文件url filter type - 同步调用
 * @param {string} zyId 
 * @param {string} tokenId 
 * @param {string} fileType 
 */
function _getZyFileUrlByType_sync(zyId, tokenId, fileType){
  fileType = fileType
  const res = service_v2.GetFileListByZyIdSync({
    zyId: zyId, 
    tokenId: tokenId
  })
  let arrResult = filterType(res, zyId, fileType)
  return arrResult
}

/**
 * 获取文件url filter name --同步调用
 * @param {string} zyId 
 * @param {string} tokenId 
 * @param {string} fileName 
 */
function _getZyFileUrlByName_sync(zyId, tokenId, fileName){
  const res = service_v2.GetFileListByZyIdSync({
    zyId: zyId,
    tokenId: tokenId
  })
  let ans = filterName(res, fileName)
  return ans
}

/**
 * 获取文件url filter name - 异步调用
 * @param {string} zyId 
 * @param {string} tokenId 
 * @param {string} fileName 
 */
async function _getZyFileUrlByName(zyId, tokenId, fileName){
  const res = await service_v2.GetFileListByZyId({
    zyId: zyId,
    tokenId: tokenId
  })
  let ans = filterName(res, fileName)
  return ans
}




export const GetZyFileUrlByFileType = _getZyFileUrlByType

export const GetZyFileUrlByFileTypeSync = normalizeWrap(_getZyFileUrlByType_sync)

export const GetZyFileUrlByFileName = _getZyFileUrlByName
export const GetZyFileUrlByFileNameSync = _getZyFileUrlByName_sync