export default [
  "caxa-wasm/xml.js",
  "caxa-wasm/main.js",
  "caxa-wasm/Command.js",
  // "caxa-wasm/zlib/rawinflate.min.js",
  // "caxa-wasm/zlib/zlib.min.js",
  "caxa-wasm/hello.js",
  "caxa-wasm/js-cfb/printj.min.js",
  "caxa-wasm/js-cfb/cfb.min.js",
  "caxa-wasm/js-cfb/hcfb.js"
]