
import { uuid } from "@/util/uuid.js";
import taskQueue from "./_taskQueue.js"


function getFile(url, fileName, resolver) {
  let _resolver = ()=>{}
  let promise = new Promise((res, rej)=>_resolver=res)
  var blob = null;
  var xhr = new XMLHttpRequest;
  xhr.open("GET", url);
  xhr.setRequestHeader("Accept", "");
  xhr.responseType = "arraybuffer";
  xhr.onload = () => {
    blob = xhr.response;
    _resolver(blob)
  };
  xhr.onerror = e => {
    // reject(e);
  };
  xhr.send();
  return promise
}


export const GetFileFromUrl = async (url, fileName) => {
  let taskId = uuid()
  taskQueue.add({id:taskId, handler: getFile.bind(null, url, fileName)})
  return taskQueue.get(taskId)
}
