import wasmUrls from "@/config/wasmUrls.js"
export function getUserInfo(){
  let str = sessionStorage.getItem("login")
  if(!str) return
  return JSON.parse(str)
}


export function fetchWasm(){
  let promises = []
  let scriptEls = []
  wasmUrls.forEach(item=>{
    let script = document.createElement("script")
    script.src = item
    if(item=="caxa-wasm/Command.js"){
      script.type = 'module'
    }
    scriptEls.push(script)
  })
  scriptEls.forEach(scriptEl=>{
    let resolve
    let promise = new Promise((res, rej)=>{resolve=res})
    promises.push(promise)
    scriptEl.onload = ()=>{
      resolve()
    }
    document.body.appendChild(scriptEl)
  })
  return Promise.all(promises).then(()=>{
    window.mitt.emit("wasm-load", true)
  })
}

export default function(){
  
}